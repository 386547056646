<template>
  <div v-loading="loading" v-if="$canAndRulesPass(user, 'successors.see_in_menu')">
    <page-heading :heading="pageTitle">
      <span slot="help"></span>
      <div slot="right">
        <div v-if="$canAndRulesPass(user, 'successors.export')">
          <el-button
            size="small"
            :loading="loadingXls"
            @click="exportToExcel"
          >Выгрузить в XLSX
          </el-button>
        </div>
      </div>
    </page-heading>


    <div style="display: flex; justify-content: space-between; gap:40px; margin-bottom: 20px">
      <div>
        <table
          class="smz-base-table successors-stats-table"
          v-loading="loading || loadingStats"
        >
          <thead>
          <tr>
            <th>Риск</th>
            <th>Позиций</th>
            <th>%</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="i in 3">
            <td>{{$companyConfiguration.settings.getEnum('successor_position_risks')[i]}}</td>
            <td>{{stats[i] || 0}}</td>
            <td>{{ (stats[i] && paginator.totalCount) ? Math.round((stats[i] / paginator.totalCount * 100 + Number.EPSILON) * 100) / 100 : 0 }}</td>
          </tr>
          <tr>
            <td><b>Итого</b></td>
            <td>{{ paginator.totalCount }}</td>
            <td></td>
          </tr>

          </tbody>
        </table>
      </div>
      <div style="display: flex; justify-content: space-between; gap: 20px; flex-flow: wrap;align-items: flex-start;align-content: flex-start;">

        <search-input
          placeholder="Поиск по ФИО и позиции"
          style="width: 350px"
          v-model="filters.search"
          @change="filtersChanged"
        >
        </search-input>

        <user-unique-prop-values-select
          placeholder="Локация"
          v-model="filters.locations"
          prop="location"
          multiple
          @selected="filtersChanged"
        ></user-unique-prop-values-select>

        <user-unique-prop-values-select
          placeholder="Дирекция"
          v-model="filters.directions"
          prop="direction"
          multiple
          @selected="filtersChanged"
        ></user-unique-prop-values-select>

        <user-unique-prop-values-select
          placeholder="Цех"
          style="width: 100px"
          v-model="filters.workshops"
          prop="workshop"
          multiple
          @selected="filtersChanged"
        ></user-unique-prop-values-select>

        <user-unique-prop-values-select
          placeholder="Должность"
          v-model="filters.positions"
          prop="position"
          multiple
          @selected="filtersChanged"
        ></user-unique-prop-values-select>

        <user-unique-prop-values-select
          v-model="filters.bands"
          placeholder="Бенд"
          style="width: 250px"
          prop="band"
          multiple
          @selected="filtersChanged"
        ></user-unique-prop-values-select>

        <el-select
          v-model="filters.ages"
          style="width:250px"
          multiple
          placeholder="Возраст"
          @change="filtersChanged"
        >
          <el-option v-for="n in 90" v-if="n>=18" :label="n" :value="n" :key="n"></el-option>
        </el-select>

        <user-select
          placeholder="ФИО НР"
          style="width: 400px"
          v-model="filters.direct_chief_user_id"
          :multiple="false"
          @selected="filtersChanged"
        ></user-select>

        <el-select
          style="width: 500px"
          v-model="filters.successors"
          clearable
          placeholder="Преемники"
          @change="filtersChanged"
        >
          <el-option label="Мои преемники" value="me"></el-option>
          <el-option label="Преемники моих непосредственных подчиненных (HP)" value="immediate_subordinates_tree_direct"></el-option>
          <el-option label="Преемники всех подчиненных" value="all_subordinates_tree_direct"></el-option>
          <el-option label="Преемники для Менеджера по персоналу (Самара)" value="all_subordinates_tree_hr_samara"></el-option>
          <el-option label="Преемники для Менеджера по персоналу (Москва)" value="all_subordinates_tree_hr_moscow"></el-option>
        </el-select>

        <el-select
          v-model="filters.position_risks"
          multiple
          placeholder="Риск позиции"
          @change="filtersChanged"
        >
          <el-option
            v-for="(name, key) in $companyConfiguration.settings.getEnum('successor_position_risks')"
            :key="key"
            :label="name"
            :value="key"
          ></el-option>
        </el-select>
      </div>

    </div>


    <successor-block
      v-for="user in users"
      style="margin-bottom: 20px"
      :key="user.id"
      :user="user"
      :evaluations="evaluations"
    ></successor-block>


    <paginator
      :paginator.sync="paginator"
      @page-changed="loadUsers"
    ></paginator>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import PageHeading from "@/components/PageHeading.vue";
import Paginator from "@/components/Paginator.vue";
import {paginator} from "@/mixins/paginator";
import {sorter} from "@/mixins/sorter";
import SuccessorBlock from "@/componentsCompany/smz/successors/SuccessorBlock.vue";
import UserUniquePropValuesSelect from "@/components/filters/users/UserUniquePropValuesSelect.vue";
import UserSelect from "@/components/filters/users/UserSelect.vue";
import SearchInput from "@/components/filters/SearchInput.vue";
import requestSender from "@/api/base/requestSender";
import {downloader} from "@/mixins/downloader";

export default {
  name: 'Successors',
  components: {SearchInput, UserSelect, UserUniquePropValuesSelect, SuccessorBlock, Paginator, PageHeading},
  mixins: [paginator, sorter, downloader],
  computed: {
    ...mapGetters(['user']),
    pageTitle() {
      return document.title;
    },
  },
  mounted() {
    this.loadUsers();
    this.getEvaluations();
  },
  data() {
    return {
      loading: false,
      loadingStats: false,
      loadingXls: false,

      evaluations: {},

      filters: {
        search: null,
        locations: [],
        directions: [],
        workshops: [],
        positions: [],
        bands: [],
        ages: [],
        direct_chief_user_id: null,
        successors: null,
        position_risks: [],

      },

      users: [],
      stats: {},
    }
  },
  methods: {
    filtersChanged() {
      this.loadUsers();
    },
    getEvaluations() {
      requestSender('get', 'evaluation/search', {})
        .then(({evaluations}) => {
          this.evaluations = evaluations;
        })
    },
    loadUsers() {

      this.loading = true;
      this.$api.user.search({
        ...this.filters,
        categories: ['Руководитель','Специалист','Служащий'],
        expand: 'currentCard.info',

        [this.sorter.param]: this.sorter.sort,
        [this.paginator.param]: this.paginator.page,
        [this.paginator.paramPerPage]: this.paginator.perPage,
      })
        .then(({data, paginator, sorter}) => {
          this.users = data.users;
          this.paginatorFill(paginator);
          this.sorterFill(sorter);
        })
        .finally(() => {
          this.loading = false;
          this.loadStats();
        })
    },

    loadStats() {
      this.loadingStats = true;
      requestSender('get', 'successor/get-search-stats', {
        ...this.filters,
      })
        .then(data => {
          this.stats = data.stats;
        })
        .finally(() => {
          this.loadingStats = false;
        })
    },

    exportToExcel() {
      this.loadingXls = true;

      this.downloadFile(
        false,
        this.$api.exportImport.export,
        {
          entity: 'successors',
          data: {
            ...this.filters,
          }
        }
      )
        .finally(()=>{
          this.loadingXls = false;
        })
    },
  }
}
</script>

<style lang="scss">
.smz-base-table.successors-stats-table {
  text-align: center;
  border-collapse: collapse;

  th, td {
    padding: 1px 10px;
    border: 1px solid #b4b4b4;
    border-collapse: collapse;
  }

}

.successors-table-risk-cell-high {
  background-color: #ffe3e3 !important;
}
.successors-table-risk-cell-normal {
  background-color: #fff9e5 !important;
}
.successors-table-risk-cell-low {
  background-color: #e8ffe0 !important;
}
</style>